<script>
  import { getFormUrl } from './utils';

  export let data;

  let showAll = false;

  const formatTime = (t) => {
    const h = Math.floor(t);
    const m = (t % 1) * 60;
    return `${h}:${m > 9 ? '' : '0'}${m}`;
  };

  const days = {
    1: 'понедельник',
    2: 'вторник',
    3: 'среда',
    4: 'четверг',
    5: 'пятница',
    6: 'суббота',
    7: 'воскресенье',
  };

  $: canShowMore = data.length > 20;
  $: visibleItems = !canShowMore || showAll ? data : data.slice(0, 15);

</script>

{#if data.length === 0}
  <p>Ничего не найдено</p>
{:else}
  <div class="wrapper">
    <table>
      <thead>
        <tr>
          <th>Метро</th>
          <th>День</th>
          <th>Время</th>
          <th>Возраст</th>
          <th>Тип</th>
          <th>Лидер</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {#each visibleItems as h}
          <tr>
            <td>{h.metro}</td>
            <td>{h.days.map((d) => days[d]).join(', ')}</td>
            <td>{formatTime(h.time)}</td>
            <td>{h.age}</td>
            <td>{h.type}</td>
            <td>{h.leader}</td>
            <td><a href={`${getFormUrl(h.leader)}`} target="_blank" class="join">Присоединиться</a></td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>

  {#if canShowMore}
    <button type="button" class="b-btn b-btn_color_black" on:click={() => {showAll = !showAll;}}>{showAll ? 'Показать меньше' : 'Показать все'}</button>
  {/if}

{/if}

<style>
  .wrapper {
    overflow: auto;
  }

  table {
    margin: 1rem 0;
    width: 100%;
    border-collapse: collapse;
  }

  th {
    text-align: left;
    font-weight: normal;
    color: #999;
  }

  td, th {
    padding: 5px 4px;
  }

  tbody td {
    border-top: 1px solid #ccc;
  }

  tbody td:last-child {
    text-align: right;
  }

  tbody tr:hover {
    background-color: #e0e0e0;
  }

  .b-btn:focus {
    outline: none;
  }
</style>
