<script>
  import MapFiltersTable from './MapFiltersTable.svelte';

  const fetchData = async () => {
    const res = await fetch('https://home-map.wolrus.org/api');
    if (res.status === 200) {
      return res.json();
    }
    throw new Error(`Ошибка ${res.status}: ${res.statusText}`);
  };

  let mapsLoaded = false;

  const initYMaps = () => {
    mapsLoaded = true;
  };
</script>

<div class="hg-widget-content">
  <script
    src="https://api-maps.yandex.ru/2.1/?apikey=718e8d5e-3cf8-44d1-9f57-f20be925e5fa&amp;lang=ru_RU"
    on:load={initYMaps}></script>
  {#await fetchData()}
    <p>Загрузка...</p>
  {:then data}
    {#if mapsLoaded}
      <MapFiltersTable {data} />
    {:else}
      <p>Загрузка карты...</p>
    {/if}
  {:catch err}
    <p>{err.message}</p>
  {/await}
</div>

<style>
  .hg-widget-content {
    font-size: 16px;
  }

  :global(a.join) {
    box-sizing: border-box;
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    font-size: 14px;
    color: #fff;
    background-color: hsl(43, 100%, 46%);
    border-radius: 4px;
    cursor: pointer;
  }

  :global(a.join:hover) {
    background-color: hsl(43, 100%, 41%);
  }

  :global(a.join:focus) {
    background-color: hsl(43, 100%, 35%);
    outline: none;
  }
</style>
