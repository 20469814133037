<script>
  import Filters from './Filters.svelte';
  import Map from './Map.svelte';
  import Table from './Table.svelte';

  export let data;

  let showMap = true;

  const emptyFilters = {
    ages: [],
    days: [],
    lines: [],
    metro: [],
    time: [],
    types: [],
  };

  let filters = { ...emptyFilters };

  $: withVisibility = data.map((h) => {
    const { days, types, ages, time, metro, lines } = filters;
    const visible =
      (days.length === 0 || days.some((d) => h.days.indexOf(d) !== -1)) &&
      (types.length === 0 || types.some((t) => h.type.indexOf(t) !== -1)) &&
      (time.length === 0 || time.some((t) => h.time === t)) &&
      (showMap || metro.length === 0 || metro.some((t) => h.metro === t)) &&
      (showMap ||
        lines.length === 0 ||
        lines.some((t) => h.location && h.location.colorName === t)) &&
      (ages.length === 0 || ages.some((a) => h.age === a));
    return {
      ...h,
      visible,
    };
  });

  $: selected = withVisibility
    .filter((i) => i.visible)
    .map(({ visible, ...i }) => i);
</script>

<div>
  <div class="button-container">
    <button
      type="button"
      class="b-btn b-btn_color_black btn-first"
      class:active={!showMap}
      on:click={() => {
        showMap = false;
      }}>Список</button
    >
    <button
      type="button"
      class="b-btn b-btn_color_black"
      class:active={showMap}
      on:click={() => {
        showMap = true;
      }}>Карта</button
    >
  </div>

  <p>Ниже вы можете выбрать фильтры, чтобы найти подходящую домашнюю группу:</p>

  <Filters {data} bind:value={filters} {showMap} />

  <Map data={withVisibility} show={showMap} />

  {#if !showMap}
    <Table data={selected} />
  {/if}
</div>

<style>
  .b-btn:focus {
    outline: none;
  }
  .b-btn.active {
    background: #353536;
    color: #fff;
  }
  .button-container {
    display: flex;
  }
  .btn-first {
    margin-right: 4px;
  }
</style>
