<script>
	import Select from 'svelte-select';

  export let data;

  export let showMap = false;

  export const dayOptions = [
    { value: 1, label: 'Понедельник' },
    { value: 2, label: 'Вторник' },
    { value: 3, label: 'Среда' },
    { value: 4, label: 'Четверг' },
    { value: 5, label: 'Пятница' },
    { value: 6, label: 'Суббота' },
    { value: 7, label: 'Воскресенье' },
  ];

  export let value;

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
  const uniqueSorted = (values) => {
    const res = values.filter(onlyUnique);
    res.sort();
    return res;
  }

  const onSelect = (prop) => (e) => {
    value[prop] = (e.detail || []).map(v => v.value);
  }

  const ageOptions = uniqueSorted(data.map(i => i.age));
  const typeOptions = uniqueSorted(data.map(i => i.type));
  const timeOptions = uniqueSorted(data.map(i => i.time)).map(t => {
    const h = Math.floor(t);
    const m = (t - h) * 60;
    return { label: `${h < 10 ? '0' : ''}${h}:${m < 10 ? '0' : ''}${m}`, value: t };
  });
  const lineOptions = uniqueSorted(data.map(i => i.location && i.location.colorName).filter(Boolean));
  const metroOptions = uniqueSorted(data.map(i => i.metro));
</script>

<div class="filters" class:for-map={showMap}>
  <div class="row">
    <div class="col">
        <Select placeholder="Возраст" items={ageOptions} isMulti={true} on:select={onSelect('ages')}></Select>
    </div>
    {#if !showMap}
      <div class="col">
          <Select placeholder="Станция метро" items={metroOptions} isMulti={true} on:select={onSelect('metro')}></Select>
      </div>
    {/if}
    <div class="col">
        <Select placeholder="День недели" items={dayOptions} isMulti={true} on:select={onSelect('days')}></Select>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <Select placeholder="Тип" items={typeOptions} isMulti={true} on:select={onSelect('types')}></Select>      
    </div>
    {#if !showMap}
      <div class="col">
        <Select placeholder="Линия метро" items={lineOptions} isMulti={true} on:select={onSelect('lines')}></Select>      
      </div>
    {/if}
    <div class="col">
      <Select placeholder="Время" items={timeOptions} isMulti={true} on:select={onSelect('time')}></Select>      
    </div>
  </div>
</div>

<style>
  .filters {
    position: relative;
    z-index: 1000;
    font-size: 14px;
    margin: 10px 0;
    --multiItemActiveBG: #ecab00;
    --multiItemActiveColor: #000;
    --itemHoverBG: #ecab00;
    --borderFocusColor: #ecab00;
    --multiSelectPadding: 0 27px 0 8px;
  }
  .row {
    display: flex;
  }
  .col {
    width: 33.333%;
  }
  .for-map .col {
    width: 50%;
  }
</style>
