<script>
  import { onMount } from 'svelte';
  import { getFormUrl } from './utils';

  export let data;

  export let show = true;

  const onlineLocation = {
    lng: 37.61778,
    lat: 55.75167,
    color: '#000',
  };

  const formatTime = (t) => {
    const h = Math.floor(t);
    const m = (t % 1) * 60;
    return `${h}:${m > 9 ? '' : '0'}${m}`;
  };

  const days = {
    1: 'понедельник',
    2: 'вторник',
    3: 'среда',
    4: 'четверг',
    5: 'пятница',
    6: 'суббота',
    7: 'воскресенье',
  };

  let mapDiv;
  let map;
  let clusterer;
  let ready;

  let marks = {};

  onMount(() => {
    ymaps.ready(() => {
      map = new ymaps.Map(
        mapDiv,
        {
          center: [55.76, 37.64],
          controls: ['zoomControl', 'typeSelector'],
          zoom: 9,
        },
        {
          balloonPanelMaxMapArea: 0,
        }
      );

      clusterer = new ymaps.Clusterer({
        clusterDisableClickZoom: true,
        clusterIconLayout: 'default#pieChart',
        clusterIconPieChartRadius: 20,
        clusterIconPieChartCoreRadius: 13,
        clusterIconPieChartStrokeWidth: 3,
        hasBalloon: true,
      });

      map.geoObjects.add(clusterer);

      data.forEach((h) => {
        if (!h.location && !h.online) {
          return;
        }
        const daysStr = h.days.map((d) => days[d]).join(', ');
        const mark = new ymaps.Placemark(
          h.location
            ? [h.location.lat, h.location.lng]
            : [onlineLocation.lat, onlineLocation.lng],
          {
            id: h.id,
            balloonContentHeader: h.leader,
            balloonContentBody: `
              <p>${h.type} ${h.age.replace(/ые$/, 'ая')}</p>
              <p>${daysStr}${daysStr.length > 0 ? ', ' : ''}${formatTime(
              h.time
            )}</p>
              ${
                h.location
                  ? `<p>
                <span class="metro-circle" style="background-color: ${h.location.color};"></span>
                ${h.location.colorName ? 'метро ' : ''}${h.metro}
              </p>`
                  : '🌍 онлайн'
              }
              <a href="${getFormUrl(
                h.leader
              )}" target="_blank" class="join">Присоединиться</a>
            `,
          },
          {
            iconColor: h.location ? h.location.color : onlineLocation.color,
          }
        );
        marks[h.id] = mark;
      });

      ready = true;
    });
  });

  $: {
    if (ready) {
      const hidden = data
        .filter((h) => !h.visible)
        .map((h) => marks[h.id])
        .filter(Boolean);
      const visible = data
        .filter((h) => h.visible)
        .map((h) => marks[h.id])
        .filter(Boolean);
      clusterer.remove(hidden);
      clusterer.add(visible);
    }
  }

  let w = 0;
</script>

<div bind:clientWidth={w} />

<div
  class="map-container"
  class:map-container-hidden={!show}
  bind:this={mapDiv}
  style={`height: 1000px; width: ${w}px`}
/>

<style>
  .map-container-hidden {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
</style>
